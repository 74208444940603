import * as request from 'superagent';
import * as _ from 'lodash';

import { Scrollbar } from 'modules/scrollbar';

import { BarGraph } from 'modules/graphs/bar';
import { RadarGraph } from 'modules/graphs/radar';
import { PieGraph } from 'modules/graphs/pie';
import { FunnelGraph } from 'modules/graphs/funnel';
import { BulletGraph } from 'modules/graphs/bullet';

import { ReportTable } from 'modules/tables/report';
import { LastTable } from 'modules/tables/last';
import { TabularTable } from 'modules/tables/tabular';

import { BaseMap } from 'modules/maps/basemap';

import { MultiKpiWidget } from 'modules/widgets/multikpi';

import { SearchSingleWidget } from 'modules/widgets/searchsingle';
import { SearchWidget } from 'modules/widgets/search';
import { AgenciesWidget } from 'modules/widgets/agencies';
import { UploadDateWidget } from 'modules/widgets/uploaddate';
import { DetailWidget } from 'modules/widgets/detail';

class ChartBox {

    constructor(element) {
        this.element = element;
        this.loading = this.element.classList.contains('is-loading');
        this.debounceChartInit = false;
    }

    init() {
        let widgetElement = this.element.querySelector('.js-widget');
        let graphElement = this.element.querySelector('.js-graph');
        let mapElement = this.element.querySelector('.js-map');
        let tableElement = this.element.querySelector('.js-table');

        if(widgetElement != null) {
            this.chartType = 'widget';
            this.initWidget(widgetElement);
        } else if(mapElement != null) {
            this.chartType = 'map';
            this.initMap(mapElement);
        } else if(graphElement != null) {
            this.chartType = 'graph';
            this.initGraph(graphElement);
        } else if(tableElement != null) {
            this.chartType = 'table';
            this.initTable(tableElement);
        }

        this.updateHeader();

        if (this.element.querySelector('.c-chartbox__body')){
            this.scrollbar = new Scrollbar(this.element.querySelector('.c-chartbox__body'));
        }

        if(!this.loading) {
            this.element.classList.add('is-loading');
            this.loading = true;
        }
    }

    updateHeader() {
        if(!_.isUndefined(this.chartConfig)) {
            let config = this.chartConfig.container;

            if(!_.isUndefined(config)) {
                let headerElement = this.element.querySelector('.c-chartbox__header');

                if(!_.isUndefined(config.image)) {
                    this.element.classList.add('has-image');

                    let imageElement = document.createElement('img');
                    imageElement.setAttribute('src', config.image);

                    let containerImageElement = document.createElement('div');
                    containerImageElement.classList.add('c-chartbox__image');
                    containerImageElement.appendChild(imageElement);

                    headerElement.appendChild(containerImageElement);
                }

                if(!_.isUndefined(config.importType)) {
                    let endpoint = '/import/last_upload_date/1/' + config.importType + '/';
                    let r = request.get(endpoint)
                    .set('Accept', 'application/json')
                    .then(response => {
                        let containerTitleElement = headerElement.querySelector('.c-chartbox__title');

                        let titleElement = document.createElement('h6');
                        if (scssVarsPath == 'skokka'){
                            titleElement.textContent = 'last update ' + response.body.upload_date;
                        }
                        else {
                            titleElement.textContent = 'dati aggiornati al ' + response.body.upload_date;
                        }
                        containerTitleElement.appendChild(titleElement);

                        if(this.debounceChartInit) {
                            this.initChart(response.body);
                        }
                    });
                }
            }
        }
    }

    render() {
        this.element.classList.remove('is-loading');
        if (this.scrollbar){
            this.scrollbar.render();
        }
        if (scssVarsPath != 'kpax'){
            if(this.chart.empty) {
                this.element.classList.add('u-hide');
                this.element.classList.add('u-margin-none');
                var index = this.element.getAttribute('data-tab-item-index');
                if (document.querySelectorAll('[data-tabby-bar-full="'+index+'"]').length > 0){
                    document.querySelectorAll('[data-tabby-bar-full="'+index+'"]')[0].classList.add('u-hide');
                }
            }
        }

        this.loading = false;
    }

    initChart(extraConfig) {
        this.debounceChartInit = false;
        this.chart.setExtraConfig(extraConfig);
        this.chart.init();
    }


    initWidget(widgetElement) {
        if(widgetElement != null) {
            let widget = null;
            let widgetType = widgetElement.dataset.widgetType;
            let widgetConfig = JSON.parse(widgetElement.dataset.widgetConfig);
            let filterParameters = widgetElement.dataset.widgetFilter;
            widgetConfig.filterParameters = filterParameters;
            if(!_.isEmpty(widgetConfig)) {
                switch(widgetType) {
                    case 'multikpi':
                        widget = new MultiKpiWidget(widgetElement, widgetConfig, this);
                        break;
                    case 'search':
                        widget = new SearchWidget(widgetElement, widgetConfig, this);
                        break;
                    case 'searchsingle':
                        widget = new SearchSingleWidget(widgetElement, widgetConfig, this);
                        break;
                    case 'agencies':
                        widget = new AgenciesWidget(widgetElement, widgetConfig, this);
                        break;
                    case 'uploaddate':
                        widget = new UploadDateWidget(widgetElement, widgetConfig, this);
                        break;
                    case 'detail':
                        widget = new DetailWidget(widgetElement, widgetConfig, this);
                        break;
                }
            }

            if(widget != null) {
                this.chartConfig = widgetConfig;
                this.chart = widget;
                window.agregg.widgets.push(this.chart);
                if(this.chartConfig.container && this.chartConfig.container.importType) {
                    this.debounceChartInit = true;
                } else {
                    this.initChart();
                }
            }
        }
    }

    initMap(mapElement) {
        if(mapElement != null) {
            let map = null;
            let mapType = mapElement.dataset.mapType;
            let mapConfig = JSON.parse(mapElement.dataset.mapConfig);
            let filterParameters = mapElement.dataset.mapFilter;
            mapConfig.filterParameters = filterParameters;
            if(!_.isEmpty(mapConfig)) {
                switch(mapType) {
                    case 'basemap':
                        map = new BaseMap(mapElement, mapConfig, this);
                        break;
                }
            }

            if(map != null) {
                this.chartConfig = mapConfig;
                this.chart = map;
                window.agregg.maps.push(this.chart);
                console.log('initMap')
                this.initChart();
            }
        }
    }

    initGraph(graphElement) {
        if(graphElement != null) {
            let graph = null;
            let graphType = graphElement.dataset.graphType;
            let graphConfig = JSON.parse(graphElement.dataset.graphConfig);
            let filterParameters = graphElement.dataset.graphFilter;
            graphConfig.filterParameters = filterParameters;
            if(!_.isEmpty(graphConfig)) {
                switch(graphType) {
                    case 'radar':
                        graph = new RadarGraph(graphElement, graphConfig, this);
                        break;
                    case 'bar':
                        graph = new BarGraph(graphElement, graphConfig, this);
                        break;
                    case 'pie':
                        graph = new PieGraph(graphElement, graphConfig, this);
                        break;
                    case 'funnel':
                        graph = new FunnelGraph(graphElement, graphConfig, this);
                        break;
                    case 'bullet':
                        graph = new BulletGraph(graphElement, graphConfig, this);
                        break;
                }
            }

            if(graph != null) {
                this.chartConfig = graphConfig;
                this.chart = graph;
                window.agregg.graphs.push(this.chart);
                if(this.chartConfig.container && this.chartConfig.container.importType) {
                    this.debounceChartInit = true;
                } else {
                    this.initChart();
                }
            }
        }
    }

    initTable(tableElement) {
        if(tableElement != null) {
            let table = null;
            let tableType = tableElement.dataset.tableType;
            let tableConfig = JSON.parse(tableElement.dataset.tableConfig);
            let filterParameters = tableElement.dataset.tableFilter;
            tableConfig.filterParameters = filterParameters;
            if(!_.isEmpty(tableConfig)) {
                switch(tableType) {
                    case 'report':
                        table = new ReportTable(tableElement, tableConfig, this);
                        break;
                    case 'tabular':
                        table = new TabularTable(tableElement, tableConfig, this);
                        break;
                    case 'last':
                        table = new LastTable(tableElement, tableConfig, this);
                        break;
                }
            }

            if(table != null) {
                this.chartConfig = tableConfig;
                this.chart = table;
                window.agregg.tables.push(this.chart);
                if(this.chartConfig.container && this.chartConfig.container.importType) {
                    this.debounceChartInit = true;
                } else {
                    this.initChart();
                }
            }
        }
    }
}

export { ChartBox }