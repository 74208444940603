import './assets/src/js/helpers/polyfills';
import "@babel/polyfill";

function importAll (r) {
  r.keys().forEach(r);
}

// IMG
let images = require.context('./assets/src/img/', true, /\.(png|ico|svg|gif|jpe?g)$/);
importAll(images);

// SVG
let svgs = require.context('./assets/src/svg/', true, /\.svg$/);
importAll(svgs);

// FONTS
let fonts = require.context('./assets/src/fonts/', true, /\.(svg|woff|woff2|eot|ttf)$/);
importAll(fonts);

// CSS
import './assets/src/css/main_skokka.scss';

// JS
import './assets/src/js/main.js';

