import * as request from 'superagent';
import * as _ from 'lodash';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import am4themes_agregg from "modules/graph-theme";
import am4adapters_agregg from "modules/graph-adapters";

import scssVars from 'helpers/scss-var-mapper';

class Graph {

    constructor(element, config, chartbox) {
        // PRIVATE PROPERTIES
        this._endpoint = '/bi/tree/';
        this._graphType = null;
        this._legend = config.legend;
        this._kpis = config.kpis;
        this._dashboardId = config.dashboardId;
        this._menuId = config.menuId;
        this._order_kpi_code = config.order_kpi_code;
        this._order_by_value = config.order_by_value;
        this._order_reverse = config.order_reverse;
        this._limit = config.limit;
        this._inheritCategory = config.inheritCategory;
        this._byKpiCode = config.byKpiCode;
        this._useKpiGroupCategory = config.useKpiGroupCategory;
        this._drillDown = config.defaultDrillDownId;
        this._containerConfig = config.container;
        this._axes = [];
        this._series = [];
        this._filterParameters = config.filterParameters;
        this._lastFullColumnValue = config.lastFullColumnValue;

        // PUBLIC PROPERTIES
        this.element = element;
        this.chartbox = chartbox;
        this.series = {};
        this.axes = {};
    }

    getDefaultConfig() {
        return {
            autoResize: true,
            decimalSeparator: ',',
            thousandsSeparator: '.'
        }
    }

    init() {
        am4core.options.commercialLicense = true;
        am4core.useTheme(am4themes_animated);
        am4core.useTheme(am4themes_agregg);

        am4adapters_agregg();

        let config = this.getDefaultConfig();
        this.graph = am4core.createFromConfig(config, this.element, this._graphType);
        if ( this.config &&  this.config.kpis && this._series) {
            if ( (this.config.kpis.length >= 2 && this._series[0].type == 'line') ) {
                //console.log('here!', this.graph);
                this.graph.colors.list = [
                    am4core.color(scssVars.palette['graph-glareblue']),
                    am4core.color(scssVars.palette['graph-yellow']),
                    am4core.color(scssVars.palette['graph-lightblue']),
                    am4core.color(scssVars.palette['graph-blue']),
                    am4core.color(scssVars.palette['graph-petrol']),
                    am4core.color(scssVars.palette['graph-beige']),
                    am4core.color(scssVars.palette['graph-blonde']),
                    am4core.color(scssVars.palette['graph-lightblue-2']),
                    am4core.color(scssVars.palette['graph-lightblue-1'])
                ];
            }
        }

        if ( this._graphType == am4charts.RadarChart ) {
            this.graph.colors.list = [
                am4core.color(scssVars.palette['graph-yellow']),
                am4core.color(scssVars.palette['graph-glareblue'])
            ]
        }

        if ( this.config && this.config.graph_color ) {  // get the firs color of the palette from config
            this.graph.colors.list = [
                am4core.color(this.config.graph_color),
                am4core.color(scssVars.palette['graph-yellow']),
                am4core.color(scssVars.palette['graph-glareblue'])
            ]
        }

        this.graph.language.locale._thousandSeparator = '.';
        this.graph.language.locale._decimalSeparator = ',';

        this.loading = true;
        //console.log(config);
        
        if (this.config && this.config.chart_height) {
            //console.log(this.config);
            this.element.style.height = this.config.chart_height;
        }
        this.fetchData();
    }

    setExtraConfig(extraConfig) {
    }

    render(graphData) {
        if(this.loading) {
            this.element.classList.add('is-loading');
        } else {
            this.element.classList.remove('is-loading');
        }
        if(graphData) {
            this.graph.data = graphData;

            if ((graphData.length > 5 && this.axes && 'ax-anno' in this.axes && this._graphType != am4charts.RadarChart) || (window.innerWidth < 1000 && graphData.length > 4  && this._graphType != am4charts.RadarChart)){
                if (this._axes && this._axes[0] && !this._axes[0].rotate){
                    this.axes['ax-anno'].renderer.labels.template.horizontalCenter = "right";
                    this.axes['ax-anno'].renderer.labels.template.verticalCenter = "middle";
                    this.axes['ax-anno'].renderer.labels.template.rotation = 300;
                    //console.log(this.config);

                    if ((this.config) && ((graphData.length > this.config.chart_zoom) || (graphData.length > this.config.chart_zoom_xs && window.matchMedia(scssVars.breakpoints['phone']).matches)) ){

                        var scrollbarX = new am4charts.XYChartScrollbar();
                        if(this._series.length > 1) {
                            _.each(this._series, series => {
                                scrollbarX.series.push(this.series[series['id']]);
                            });
                        } else {
                            scrollbarX.series.push(this.series['sr-requests']);
                        }

                        scrollbarX.backgroundColor = am4core.color(scssVars.palette['darkgrey']);
                        scrollbarX.background.fill = am4core.color(scssVars.palette['darkgrey']);     
                        scrollbarX.background.fillAlpha = .9;
                        scrollbarX._unselectedOverlay.fill = am4core.color(scssVars.palette['darkgray-shade-2']);     
                        scrollbarX._unselectedOverlay.fillAlpha = 1;
                        scrollbarX._unselectedOverlay.stroke = am4core.color(scssVars.palette['darkgray-shade-2']);
                        scrollbarX.scrollbarChart.series.values[0].filters.values[0].saturation = 0;
                        scrollbarX.scrollbarChart.xAxes.getIndex(0).renderer.labels.template.disabled = true;
                        scrollbarX.thumb.background.stroke = am4core.color(scssVars.palette['charcoal-glare-2']);
                        scrollbarX.thumb.background.fill = am4core.color(scssVars.palette['darkgrey']);     
                        scrollbarX.startGrip.background.fill = am4core.color(scssVars.palette['charcoal-glare-2']);
                        scrollbarX.startGrip.background.stroke = am4core.color(scssVars.palette['charcoal-glare-2']);
                        scrollbarX.startGrip.children.fill = am4core.color(scssVars.palette['charcoal-glare-2']);
                        scrollbarX.endGrip.background.fill = am4core.color(scssVars.palette['charcoal-glare-2']);
                        scrollbarX.endGrip.background.stroke = am4core.color(scssVars.palette['charcoal-glare-2']);
                        scrollbarX.endGrip.hoverOptions.background = am4core.color(scssVars.palette['charcoal-glare-2']);
                        scrollbarX.dx = -15;
                        //console.log(scrollbarX.scrollbarChart.series.values[0])
                        this.graph.scrollbarX = scrollbarX;
                        this.graph.scrollbarX.parent = this.graph.bottomAxesContainer;

                        if ( (scssVarsPath == 'skokka' || scssVarsPath == 'kpax') && graphData.length > 31){
                            scrollbarX.start = 1 - (30 / graphData.length); // da 0 a 1
                        }

                        if (scssVarsPath == 'skokka' || scssVarsPath == 'kpax'){
                            scrollbarX.backgroundColor = am4core.color(scssVars.palette['tidal-glare']);
                            scrollbarX.background.fill = am4core.color(scssVars.palette['tidal-glare']);     
                            scrollbarX.background.fillAlpha = .9;
                            scrollbarX._unselectedOverlay.fill = am4core.color(scssVars.palette['charcoal-glare']);     
                            scrollbarX._unselectedOverlay.fillAlpha = 1;
                            scrollbarX._unselectedOverlay.stroke = am4core.color(scssVars.palette['charcoal-glare-2']);
                            scrollbarX.scrollbarChart.series.values[0].filters.values[0].saturation = 0;
                            scrollbarX.scrollbarChart.xAxes.getIndex(0).renderer.labels.template.disabled = true;
                            scrollbarX.thumb.background.stroke = am4core.color(scssVars.palette['charcoal-glare-2']);
                            scrollbarX.thumb.background.fill = am4core.color(scssVars.palette['tidal-glare']);     
                            scrollbarX.startGrip.background.fill = am4core.color(scssVars.palette['charcoal-glare-2']);
                            scrollbarX.startGrip.background.stroke = am4core.color(scssVars.palette['charcoal-glare-2']);
                            scrollbarX.startGrip.children.fill = am4core.color(scssVars.palette['charcoal-glare-2']);
                            scrollbarX.endGrip.background.fill = am4core.color(scssVars.palette['charcoal-glare-2']);
                            scrollbarX.endGrip.background.stroke = am4core.color(scssVars.palette['charcoal-glare-2']);
                            scrollbarX.endGrip.hoverOptions.background = am4core.color(scssVars.palette['charcoal-glare-2']);
                        }

                        if (scssVarsPath == 'skokka'){
                            this.graph.zoomOutButton.disabled = true;
                        }
                    }

                }
            }

            if ( this._graphType == am4charts.RadarChart ) {
                if (window.innerWidth < 1000 && graphData.length > 0 && 'ax-anno' in this.axes)
                    //console.log(this.graph);
                    this.axes['ax-anno'].renderer.labels.template.fontSize = '12px';
                
            }
        }

        this.graph.tapToActivate = true;

        this.renderCallback();
    }

    generateAxes() {
        console.log('Axes generation!');
    }

    generateSeries() {
        console.log('Series generation!');
    }

    generateCursor() {
        console.log('Cursor generation!');
        // this.graph.cursor = new am4charts.XYCursor();
    }

    generateLegend() {
        if(this._legend) {
            let legend = new am4charts.Legend();
            legend.markers.template.width = 20;
            legend.markers.template.height = 20;
            legend.markers.template.marginRight = 5;
            legend.useDefaultMarker = true;

            this.graph.legend = legend;
            this.graph.legend.disabled = true;
            this.graph.legend.hide();

            let _gr = this.graph;
            setTimeout(function (){
                _gr.legend.disabled = false;
                _gr.legend.itemContainers.template.marginLeft = -10;
                _gr.legend.itemContainers.template.marginRight = -10;
                _gr.legend.itemContainers.template.paddingTop = 5;
                _gr.legend.itemContainers.template.paddingBottom = 5;
                _gr.legend.show();
            }, 1000);
        }

    }

    fetchData() {
        let requests = [];
        let order = 0;
        // PREPARE REQUESTS BASED ON KPI CONFIG
        _.each(this._kpis, kpi => {
            let params = {
                kpi: kpi.key,
                menu_id: this._menuId,
                order_kpi_code: this._order_kpi_code,
                order_by_value: this._order_by_value,
                order_reverse: this._order_reverse,
                limit: this._limit,
                from_who: 1,
                debug: window.agregg.debugMode,
                order: order,
            };
            if(this._drillDown != undefined) {
                params['drill_down_id'] = this._drillDown;
            }
            order += 1;
            let r = request.get(this._endpoint)
            .set('Accept', 'application/json')
            .query(params).query(this._filterParameters).then(response => {
                return response.body;
            });
            requests.push(r);
        });
        // HANDLE REQUESTS RESPONSES
        Promise.all(requests).then(responses => {

            var ordered = {};   
            _(responses).keys().sort().each(function (key) {
                ordered[key] = responses[key];
            });

            responses = ordered;

            let graphData = [];
            let first = true;
            let allReset = true;

            // se non ho figli ciclo fin tanto che data.reset == true
            for (var key in responses) {
                let ch = responses[key];
                if (!ch.reset){
                    allReset = false;
                }
            }

            for (var key in responses) {
                let ch = responses[key];
                if (ch.children.length == 0){
                    delete responses[key];
                    continue;
                }
                if (ch.reset){
                    break;
                }
                else {
                    delete responses[key];
                }
            }

            let lastFullColumnValue = null;
            let maxPeriodId = 0;

            _.each(responses, data => {
                let kpi = _.find(this._kpis, {key: data.kpi_code});
                _.each(data.children, set => {
                    let category;
                    if (this._inheritCategory) {
                        category = data.kpi_title;
                    } else {
                        category = set.name;
                    }   

                    if (this._useKpiGroupCategory) {
                        if (data.kpi_group) {
                            category = data.kpi_group;
                        }
                    }
                    let index = 0;
                    _.each(set.values, dataSet => {
                        let kpiData = {};
                        let open = 0;
                        let value = parseFloat(dataSet.size);
                        let stepValue = parseFloat(dataSet.size);
                        if ((value != 0 && !isNaN(value)) || (this._series[0].type == 'line' && !isNaN(value) )
                            || (  this._graphType == am4charts.RadarChart ) || this.config.showZero == 'true' ){
                            if (this._graphType == am4charts.RadarChart) {
                                if (isNaN(value)) {
                                    value = 0;
                                }
                            }
                            kpiData['displayValue'] = value;

                            if (!data.reset && graphData.length > 0){
                                open = parseFloat(graphData[graphData.length-1][kpi.value]); // valore precedente
                                value = parseFloat(graphData[graphData.length-1][kpi.value]) + parseFloat(value); // valore finale
                                stepValue = value - open; // valore finale
                                kpiData['displayValue'] = stepValue.toFixed(this.config.numOfDecimals).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.','_').replace(',','.').replace('_',',') + ' (' + (stepValue/lastFullColumnValue * 100).toFixed(this.config.numOfDecimals) + '%)';
                            }
                            else {
                                if (!lastFullColumnValue){
                                    lastFullColumnValue = value;
                                }
                                if (!this._lastFullColumnValue){
                                    lastFullColumnValue = value;
                                }
                            }

                            kpiData[kpi.category] = category;
                            if (this._byKpiCode) {
                                kpiData[data.kpi_code] = value;  // Da abiltiare per grafico a linea
                            }
                            else {
                                kpiData[kpi.value] = value;
                                if (!data.reset && graphData.length > 0){
                                    kpiData[kpi.value] = value;
                                }
                            }
                            if (this._useKpiGroupCategory) {
                                kpiData[category] = value;
                                kpiData[kpi.kpi_title] = value;
                            }

                            maxPeriodId = Math.max(maxPeriodId, parseInt(dataSet.period_id) )
                                
                            kpiData['open'] = open;
                            kpiData['stepValue'] = stepValue;
                            kpiData[dataSet.period] = value;

                            if (this._drillDown == 5 && index > 0) { // multi period drill down
                                kpiData[dataSet.period] = value;
                                if (first){
                                    this._series[0].dx = 15 // reduce width and dx
                                    
                                    this._series.push({
                                        'id': "sr-requests" + index,
                                        'label': "",
                                        'rotate': false,
                                        'show_percentage': false,
                                        'stacked': false,
                                        'type': "column",
                                        'year_name': dataSet.period,
                                        'data': {
                                            'category': 'anno',
                                            'value': dataSet.period,
                                        },
                                        //'dx': true,
                                    })
                                    first = false; 
                                }
                            }
                            else if (this._drillDown == 5){
                                this._series[0].year_name = dataSet.period;
                                this._series[0].data.value = dataSet.period;
                            }

                            //kpiData['color'] = dataSet.color;
                            let f = false;
                            _.each(graphData, gd => {
                                if (gd[kpi.category] == kpiData[kpi.category]){
                                    gd[data.kpi_code] = value;
                                    gd[kpi.kpi_title] = value;

                                    /*if (data.color){
                                        gd['color' + data.kpi_code ] = data.color;
                                    }*/

                                    f = true;
                                    if (this._drillDown == 5 && index > 0) { // multi period drill down
                                        gd[dataSet.period] = value;
                                    }
                                }
                            })
                            if (!f){
                                graphData.push(kpiData);
                            }

                            index += 1;
                        }

                    });
                });
            });

            if (this.config.period_to_hide_from && maxPeriodId >= parseInt(this.config.period_to_hide_from) )  {
                this.empty = true;
            }

            if (this.config.periods_to_hide && JSON.parse(this.config.periods_to_hide ).includes(maxPeriodId))  {
                this.empty = true;
            }

            if(graphData.length == 0) {
                this.empty = true;
            }
            this.loading = false;
            this.generateAxes();
            this.generateSeries();
            this.generateCursor();

            this.render(graphData);
            this.generateLegend();

            if (this._axes){
                let axisConfig = this._axes[0];
                if (axisConfig && axisConfig.rotate){
                    graphData = graphData.reverse();
                }
            }
            
        });
    }

    renderCallback() {
        if(this.chartbox) {
            this.chartbox.render();
        }
    }
}

export { Graph }