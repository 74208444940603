import * as request from 'superagent';
import * as _ from 'lodash';
import { Table } from 'modules/table';

class LastTable extends Table {

    constructor(element, config, chartbox) {
        super(element, config, chartbox);
        this._tableType = 'last';
    }

    generateRow(rowData, use_split) {
        // GENERIC ROW DEFINITION
        let kpis = _.isUndefined(rowData.kpis) ? [] : rowData.kpis.split(';');
        let cells = [];
        if (use_split){
            _.each(rowData.name.split(' - '), txt => {
                cells.push({'content' : txt, rowSpan: 1, colSpan:1, type: 'text'});
            });
        }
        else {
            cells.push({'content' : rowData.name, rowSpan: 1, colSpan:1, type: 'text'});
        }
        return {
            id: rowData.id,
            order: rowData.order,
            clickable: rowData.clickable || false,
            togglable: rowData.togglable || false,
            parentRow: rowData.parent_click_row || 0,
            class: rowData.class,
            kpis: kpis,
            cells: cells
        };
    }

    generateCell(cellData, row) {
        let existingCell = _.find(row.cells, { kpi: cellData.kpi_code });

        let c = cellData.formatted_size_symbol.trim().replace(/(&nbsp;)/g,' - ');
        
        if (!parseFloat(cellData.size) > 0 && parseFloat(c) == 0){
            c = ' - ';
        }
        let cell = {
            type: cellData.kpi_measure_type,
            periodId: cellData.period__period_id,
            content: c,
            value: parseFloat(cellData.size),
            kpi: cellData.kpi_code,
            analysisResult: cellData.kpi_analysis_result,
            order: _.isUndefined(existingCell) ? row.cells.length + 1 : existingCell.order,
            colSpan: 1,
            rowSpan: 1
        };
        if(this._emptyValues.includes(cell.content)) {
            cell.type = 'empty';
            cell.content = this._emptyCellContent;
        }
        return cell;
    }

    addRow(rowData, use_split) {
        let row = _.find(this.table.body, { id: rowData.name });
        if(_.isUndefined(row)) {
            row = this.generateRow(rowData, use_split);
            this.table.body.push(row);
        }
        _.each(rowData.values, cellData => {
            this.addCell(cellData, row.id);
        });
        this.updateGrid(row);
    }

    getCustomParams() {
        return {
            is_table: 1,
        };
    }

    renderCallback() {
        if(this.chartbox) {
            this.chartbox.render();
        }

        if (this.cache_key) {
            this.updateCache();
        }

        if (this._order_by_value){

            let kpi_lists = this._order_kpi_code.split(';');
            let sortinIndex = undefined;
            let sortinIndexSecondary = undefined;
            let c0 = undefined;
            
            let clickableHeaders = this.element.querySelectorAll('.c-table__row--title');
            _.each(clickableHeaders, row => {
                _.each(row.cells, cell => {
                    if (this._order_reverse){
                        cell.classList.add('order-reverse');
                    }
                    
                    if ( kpi_lists[0] == cell.dataset.kpi ){
                        sortinIndex = cell.cellIndex;
                        c0 = cell;
                    }
                    if ( kpi_lists[1] == cell.dataset.kpi ){
                        sortinIndexSecondary = cell.cellIndex;
                    }
                });
            });

            if (sortinIndex){
                this.sortTableByInput(c0, sortinIndex, sortinIndexSecondary);
            }

        }

        if (this.config.cols_to_bold) {
            this.config.cols_to_bold.forEach(col_to_bold => {
                this.bold_column(col_to_bold);
            });
        }
            
    }

    sortTableByInput(cell, col_index, col_index_secondary) {
        let table, rows, switching, i, x, v1_s, v2_s, x_s, y_s, y, shouldSwitch;
        table = this.element;
        switching = true;
        
        let reverse = cell.classList.contains('order-reverse');

        while (switching) {
            switching = false;
            rows = table.rows;
            for (i = 1; i < (rows.length - 1); i++) {
                shouldSwitch = false;
                x = rows[i].getElementsByTagName("td")[col_index];
                y = rows[i + 1].getElementsByTagName("td")[col_index];
                let v1 = parseFloat(x.innerText.toLowerCase().replace(/\./g,''));
                let v2 = parseFloat(y.innerText.toLowerCase().replace(/\./g,''));
                
                if (col_index_secondary){
                    x_s = rows[i].getElementsByTagName("td")[col_index_secondary];
                    y_s = rows[i + 1].getElementsByTagName("td")[col_index_secondary];
                    v1_s = parseFloat(x_s.innerText.toLowerCase().replace(/\./g,''));
                    v2_s = parseFloat(y_s.innerText.toLowerCase().replace(/\./g,''));
                }
                
                if (reverse){
                    if ( v1 < v2 ) {
                        shouldSwitch = true;
                        break;
                    }
                    if (col_index_secondary && v1 == v2){
                        if ( v1_s < v2_s ) {
                            shouldSwitch = true;
                            break;
                        }
                    }
                }
                else {
                    if (v1 > v2 ) {
                        shouldSwitch = true;
                        break;
                    }
                    if (v1 == v2){
                        if ( col_index_secondary &&  v1_s > v2_s ) {
                            shouldSwitch = true;
                            break;
                        }
                    }
                }
            }
            if (shouldSwitch) {
                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                switching = true;
            }
        }
        cell.classList.toggle('order-reverse');
    }

    bold_column(col_no) {
        var rows = this.element.getElementsByTagName('tr');
        for (var row = 0; row < rows.length; row++) {
            var cels = rows[row].getElementsByTagName('td')
            if (!cels || cels.length == 0){
                var cels = rows[row].getElementsByTagName('th')
            }
            cels[col_no].style['font-weight']="700";
        }
    }

}

export { LastTable }